import React, { useState , useEffect, useContext, useRef} from 'react';
import Logo from '../images/logo.png';
import '../css/pair.css'



import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faInfo, faDashboard, faBookBookmark, faCalendar,
     faPuzzlePiece , faShieldAlt, faDiceOne, faBahtSign, faChartBar, faTools
    , faBell, faChevronDown, faArrowUp,
    faUser,
    faGift,
    faMessage,
    faUserAlt,
    faCheck} from '@fortawesome/free-solid-svg-icons';

import { Link, useNavigate } from 'react-router-dom'
import { ShareContext } from '../context/sharecontext';
import * as Constants from '../constants';
import LoadingImg from '../images/loading.gif';
import GCustomerHeader from '../partials/gCustomerHeader';
import GDialog from '../components/gDialog';


function Win() {
    const { profile, token, activepair, activelevel,setActivePair, setToken, setActiveLevel } = useContext(ShareContext);
    const [staySigned, setStaySigned] = useState(false)
    //load profile info.
    const [user, setUser] =useState({});
    const [loading, setLoading] =useState(false);
    const [cards, setCards]=useState([]);
    const [sec, setSec] =useState(20);
    const count = useRef();
    const [isCashout, setIsCashout]=useState(false);
    const [cashed,setCashed] = useState(false);
    const navigate = useNavigate();
    const [msgerr, setMsgerr] = useState('please see');
    const colorA='#13B7FD';
    const colorB='#36981D';
    const color0="black";
    const wincolor="#11790FF2";
    const loosecolor="#F90B0BF2";
    const [status, setStatus]=useState('');
    

    useEffect(() => {
      

      if(activepair==null){
        //navigate('/dashboard')
        var acp=localStorage.getItem('activepair');
        if(acp==null){
          navigate('/dashboard')
        }else{
          var _acp=JSON.parse(acp);
          setActivePair(_acp);
        
        }
      }

      if(activelevel==null){
        
        var acp=localStorage.getItem('level');
        if(acp==null){
          navigate('/dashboard')
        }else{
          var _acp=JSON.parse(acp);
          setActiveLevel(_acp);
         
        }
      }
      if(token==null){
        var str_token= localStorage.getItem("token");
        setToken(str_token);
      }

    



     // setSec(activelevel.expireSec);
      //count.current=activelevel.expireSec;
if(activelevel!=null){
  Load();
       if(activelevel.expireSec>0){
            onExpireTime();  
       }
}
       
       

         }, [activelevel]);

    
  const Load=async()=>{
      
            var urls=`${Constants.rooturl}/game/checkturn/${activepair.id}`;
            try {
               // setLoading(true);
                const resp = await fetch(urls, { 
                  method: 'GET',
                  headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token,
                    
                  },
                });
                
                const data = await resp.json();
              
               // setLoading(false);
                console.log(data);
                if (data.code === 0) {

                        setActivePair({...activepair, pairBscore:data.pairBscore,pairAscore:data.pairAscore,winnerId:data.winnerId});
                        LoadInterest();  
                }else {    
                  console.error(data.status);
                }
              } catch (error) {
                setLoading(false);
                console.error(error); 
              }
  }

  const LoadInterest=async()=>{
      
    var urls=`${Constants.rooturl}/game/intereststatus/${profile.id}/${activelevel.gameId}`;
    try {
       // setLoading(true);
        const resp = await fetch(urls, { 
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': token,
            
          },
        });
        
        const data = await resp.json();
        setStatus(data);
       
      } catch (error) {
        setLoading(false);
        console.error(error); 
      }
}
    
  const onExpireTime=()=>{
           // const newsec=sec-1;
            setSec(count.current);
            count.current = count.current -1;
           // setSec(newsec);
            if(count.current>0){
                setTimeout(onExpireTime,1000);
            }
            
  }
  const onCashout=async()=>{
            
    
    setLoading(true);
   
    try {
      var v={levelId:activelevel.id, gameId:activelevel.gameId}; //+1 include the present one about to call
      const resp = await fetch(`${Constants.rooturl}/game/cashout`, {
        method: 'POST',
        body:JSON.stringify(v) ,
        headers: {
          'Content-Type': 'application/json, text/plain',
          'Authorization': token,
        },
      });
      
      const data = await resp.json();
      setLoading(false);
      console.log(data);
      if (data.code === 0) {
        //on success, show dialog and msg
        setIsCashout(false);
        setCashed(true);
      } else{
        setMsgerr(data.status);
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
     // setErr(Constants.serverErr);
      setMsgerr(Constants.networkerr)  
     // onMove();
    }
 }

 const onCashedOk=()=>{
  setCashed(false);
  navigate('/dashboard');
 }

  return (
 
    activepair!=null && <div>
      {loading && <div>
        <img src={LoadingImg} style={{width:300, marginLeft:'auto', marginRight:'auto'}} />
        </div>}  
    
        <div className='homebar'>
        
        <div className='logoside'>
          <img src={Logo}/> 
          <span>TWO LEAVES GAMES</span>
        </div>
        <div style={{display:'flex'}}>
            <div class='timeIndicator'><p>
                <span>Time</span><span>0:00</span>
            </p>
                
            </div>
        </div>
        <div className='menu'>
          <ul>
            
            <li ><Link to="/"><FontAwesomeIcon icon={faMessage}  /></Link></li>
            <li ><Link to="/"><FontAwesomeIcon icon={faUser}  /></Link></li>
            
          </ul>
        </div>
      </div>
<div className='cusBody'> 
    
    <div className='innerBox'>
        <div style={{flex:1}}> 
            <div style={{display:'flex',flexDirection:'row'}}><p>Level {activelevel?.levelNo}</p><p style={{flex:1,textAlign:'center', fontWeight:'bold', fontSize:30, color:activepair.winnerId==profile?.id?wincolor:loosecolor}}>{activepair.winnerId==profile?.id?'You Win':'You Loose'}</p></div>
            <div className='pairmember'>
            <div>
                <div className='avatar'>
                    <FontAwesomeIcon icon={faUserAlt} style={{fontSize: (activepair.winnerId==activepair.pairA.id)? 100:40, color:colorA}}  />
                </div>
            
            <p className='playername'>{activepair.pairA.firstname}</p>
            <div>
                <span className='playerphone'>{activepair.pairA.phone}</span><br/>
                <span className='playersocre'>Score: {activepair.pairAscore} </span>
            </div>
                    <div style={{color:(activepair.winnerId==activepair.pairA.id)? wincolor:loosecolor, fontWeight:'bold'}}>{(activepair.winnerId==activepair.pairA.id)? 'Winner':'Looser'}</div>
            </div>
           
        <div>
                <div className='avatar'>
                    <FontAwesomeIcon icon={faUserAlt} style={{fontSize:(activepair.winnerId==activepair.pairB.id)? 100:40, color:colorB}}  />
                </div>
            
                <p className='playername'>{activepair.pairB.firstname}</p>
            <div>
                <span className='playerphone'>{activepair.pairB.phone}</span><br/>
                <span className='playersocre'>Score: {activepair.pairBscore}</span>
            </div>
            <div style={{color:(activepair.winnerId==activepair.pairB.id)? wincolor:loosecolor, fontWeight:'bold'}}>{(activepair.winnerId==activepair.pairB.id)? 'Winner':'Looser'}</div>
            </div>
       </div>

        </div>
    </div>
        <div className='innerBox'>
          {activepair.winnerId==profile?.id && <div className='waiting'>
                Waiting for Level {activelevel?.levelNo+1}
            </div>}  

            {activepair.winnerId!=profile?.id && <div className='waiting'>
                The Game is over for you. You will be notify on next game
                <br/>
                <strong style={{color:'red'}}>{status}</strong>
            </div>}  

           {activelevel?.cashoutAmount>0 && activepair.winnerId==profile?.id && <div className='cashoutbtn'>
                <button onClick={()=>setIsCashout(true)}>Cashout</button>
                <button ><span className='blink_me'>Expire in 0:{sec} seconds</span><span>{Constants.formatCurrency(activelevel?.cashoutAmount) }</span></button>
            </div>}  
            
        </div>

        <div style={{textAlign:'center'}}>
          <button className='btn_pry_red' onClick={()=>navigate("/dashboard")}>Close</button>
        </div>
    </div>
         
         <GDialog open={isCashout} text={`Note:  You have indicate that you want to Cashout!  
            The sum of ${Constants.formatCurrency(activelevel?.cashoutAmount)} will be added to your wallet. You can move cash in your wallet to your bank account on record. Click Proceed to continue or Cancel `}
             isAlert={false} onProceed={onCashout} onCancel={()=> setIsCashout(false)} loading={loading} msgerr={msgerr} />
         <GDialog open={cashed} text={`Sum of ${Constants.formatCurrency(activelevel?.cashoutAmount)} moved to your wallet.` } isAlert={true} onProceed={onCashedOk} />

    </div>

  )
}

export default Win