import React, { useState , useEffect, useContext} from 'react';
import Logo from '../images/logo.png';

import img1 from '../images/img1.png';
import img2 from '../images/img2.png';
import img6 from '../images/img6.png';
import img7 from '../images/img7.png';
import img8 from '../images/img8.png';
import img9 from '../images/img9.png';

import '../css/dashboard.css'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faInfo, faDashboard, faBookBookmark, faCalendar,
     faPuzzlePiece , faShieldAlt, faDiceOne, faBahtSign, faChartBar, faTools
    , faBell, faChevronDown, faArrowUp,
    faUser,
    faGift} from '@fortawesome/free-solid-svg-icons';

import { Link, useNavigate } from 'react-router-dom'
import { ShareContext } from '../context/sharecontext';
import * as Constants from '../constants';
import LoadingImg from '../images/loading.gif';
import GCustomerHeader from '../partials/gCustomerHeader';


function Interest() {
    const { profile, token, setProfile } = useContext(ShareContext);
    const [staySigned, setStaySigned] = useState(false)
    //load profile info.
    const [user, setUser] =useState({});
    const [loading, setLoading] =useState(false);
    const [activities, setActivities]=useState([]);

    console.log(profile);
    useEffect(() => {
     
       //Load();
       //LoadReports();
         }, []);
    
    const Load=async()=>{
      
        var urls=`${Constants.rooturl}/customer/${profile.id}`;
        try {
            setLoading(true);
            const resp = await fetch(urls, { 
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': token,
                
              },
            });
            
            const data = await resp.json();
          
            setLoading(false);
            console.log(data);
            if (data.code === 0) {
                
                setProfile({...profile, unitBalance:data.res.unitBalance} );
            }else {    
              console.error(data.status);
            }
          } catch (error) {
            setLoading(false);
            console.error(error); 
          }
     }
     const LoadReports=async()=>{
      
      var urls=`${Constants.rooturl}/customer/activities/${profile.id}`;
      try {
          setLoading(true);
          const resp = await fetch(urls, { 
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': token,
              
            },
          });
          
          const data = await resp.json();
        
          setLoading(false);
          console.log(data);
          if (data.code === 0) {
              
              //setProfile({...profile, unitBalance:data.res.unitBalance} );
              setActivities(data.reqcall);
          }else {    
            console.error(data.status);
          }
        } catch (error) {
          setLoading(false);
          console.error(error); 
        }
   }

  return (
 
    <div>
      {loading && <div>
        <img src={LoadingImg} style={{width:300, marginLeft:'auto', marginRight:'auto'}} />
        </div>}  
    
    <GCustomerHeader active="dashboard" />
<div className='cusBody'> 
    <div className='title'>
        <h3>Welcome {profile.firstname} {profile.lastname}!</h3>
        <button className='btn_sec_red'>Cancel</button>
    </div>
    
    <div  style={{height:400}}>
        <div className='innerBox' style={{margin:'auto'}}>
            <div style={{textAlign:'center'}}>
                <p>{`Note:  You have indicated your interest to Paticipate in the game,  
            one time fee of N200 will be charge from your wallet  to be qualify for the game. Kindly fund your wallet with a  minimum of N200`}</p>
            <button className='btn_primary'>Proceed</button>
            </div>
            
        </div>
    </div>
    </div>
         
    </div>

  )
}

export default Interest