import React, { useState , useEffect, useContext} from 'react';



import Logo from '../images/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Link, useNavigate } from 'react-router-dom'
import { ShareContext } from '../context/sharecontext';



function GHeader(props) {

    
    useEffect(() => {
      
     }, []);

     const navigate = useNavigate();
     const onLogin=()=>{
         navigate('/login');
     }
     const onRegister=()=>{
        navigate('/register');
    }
  return (
    <div className='homebar'>
        
    <div className='logoside'>
      <img src={Logo}/> 
      <span>TWO LEAVES GAMES</span>
    </div>
    
    <div className='menu'>
      <ul>
        <li className={props.active=='home'?'underline':''}><Link to="/">Home</Link></li>
        <li className={props.active=='faq'?'underline':''}><Link to="/faq">FAQ</Link></li>
        <li><button className='btn_sec' onClick={onLogin}>Login</button></li>
        <li><button className='btn_primary' onClick={onRegister}>Register Today</button></li>
      </ul>
    </div>
  </div>
  )
}

export default GHeader