import React, { useState , useEffect, useContext} from 'react';
import Logo from '../images/logo.png';

import img1 from '../images/img1.png';
import img2 from '../images/img2.png';
import img6 from '../images/img6.png';
import img7 from '../images/img7.png';
import img8 from '../images/img8.png';
import img9 from '../images/img9.png';

import '../css/admin.css'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faInfo, faDashboard, faBookBookmark, faCalendar,
     faPuzzlePiece , faShieldAlt, faDiceOne, faBahtSign, faChartBar, faTools
    , faBell, faChevronDown, faArrowUp,
    faUser,
    faGift} from '@fortawesome/free-solid-svg-icons';

import { Link, useNavigate } from 'react-router-dom'
import { ShareContext } from '../context/sharecontext';
import * as Constants from '../constants';
import LoadingImg from '../images/loading.gif';
import GCustomerHeader from '../partials/gCustomerHeader';
import GDialog from '../components/gDialog';
import GAdminHeader from '../partials/gAdminHead';
import GAdminSidebar from '../partials/adminSidebar';


function Profile() {
    const navigate = useNavigate();
    const { profile, adminToken, setAdminProfile } = useContext(ShareContext);
    const [staySigned, setStaySigned] = useState(false)
    const [game, setGame]=useState(null);
    const [level,setLevel]  =useState(null);
    //load profile info.
  
    const [loading, setLoading] =useState(false);
 


    console.log(profile);
    useEffect(() => {
     
       Load();
       //LoadReports();
         }, []);
    
    const Load=async()=>{
      
        var urls=`${Constants.rooturl}/game/dashboard`;
        try {
            setLoading(true);
            const resp = await fetch(urls, { 
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': adminToken,
                
              },
            });
            
            const data = await resp.json();
          
            setLoading(false);
            console.log(data);
            if (data.code === 0) {
               
            }else {    
              console.error(data.status);
            }
          } catch (error) {
            setLoading(false);
            console.error(error); 
          }
     }
    
   const onInterest=async()=>{
      setLoading(true);
     
      try {
        var v={};//{levelId:level.id,gameId:game.id,customerId:profile.id}; //+1 include the present one about to call
        const resp = await fetch(`${Constants.rooturl}/game/showinterest`, {
          method: 'POST',
          body:JSON.stringify(v) ,
          headers: {
            'Content-Type': 'application/json, text/plain',
            'Authorization': adminToken,
          },
        });
        
        const data = await resp.json();
        setLoading(false);
        console.log(data);
        if (data.code === 0) {
          //on success, show dialog and msg
           
        } 
      } catch (error) {
        setLoading(false);
        console.error(error);
       // setErr(Constants.serverErr);
      
       // onMove();
      }
   
   }
   const onStartGame =()=>{

   }

  return (
 
    <div>
      {loading && <div>
        <img src={LoadingImg} style={{width:300, marginLeft:'auto', marginRight:'auto'}} />
        </div>}  
    
<div className='topAdmin'>
            <GAdminSidebar active="profile" />
    <div className='contentside'>
    <GAdminHeader />
    <div className='innerBox'>
     <div className='groupWidget'>
        <div className='widget'>
            <p>Total Amount Receive on Game</p>
            <p>N1,000,000</p>
        </div>
        <div className='widget'>
            <p>Total Member</p>
            <p>N1,000,000</p>
        </div>
        <div className='widget'>
        <p>Total Number Game </p>
        <p>N1,000,000</p>
        </div>
        <div className='widget'>
        <p>Total Cashout  </p>
        <p>N1,000,000</p>
        </div>
        <div className='widget'>
        <p>Total Wallet Amount </p>
        <p>N1,000,000</p>
        </div>
        <div className='widget'>
        <p>Total Withdraws </p>
        <p>N1,000,000</p>
        </div>
    </div>   
    </div>
    
  
    </div>

</div>

    </div>

  )
}

export default Profile