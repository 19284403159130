import React, { useState } from 'react';
import '../css/ginput.css';


import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faInfo, faCopyright, faPhone } from '@fortawesome/free-solid-svg-icons';
import LoadingImg from '../images/loading.gif';


function GExpired(props) {
    const navigate = useNavigate();
    const ActLogout=()=>{
        
        //save both token and profile on storage and set current date, so you can compare the date during refresh
       localStorage.removeItem("profile" )
       localStorage.removeItem("token");
       localStorage.removeItem("tokendate"); //this is keeping the date to try to relunch token or not
        navigate(props.type=='admin'?'/admin/login': '/login');
      }

  return (
    <div className= {`dialog ${props.open?'show':''}`}>
          <div className='content'>
          <div className='innerBox' style={{margin:'auto'}}>
            <div style={{textAlign:'center'}}>
                <p>Session Expired. Please login again</p>
               
            
           <button className='btn_primary' onClick={ActLogout}> Login Again</button>
           
            </div>
            
        </div>
       {/*  <div><p>
                {props.msgerr}
            </p></div> */}
          </div>
              
         </div>
  )
}

export default GExpired