import React, { useState } from 'react';
import '../css/ginput.css';


import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faInfo, faCopyright, faPhone } from '@fortawesome/free-solid-svg-icons';
import LoadingImg from '../images/loading.gif';

function GDialog(props) {
    

  return (
    <div className= {`dialog ${props.open?'show':''}`}>
          <div className='content'>
          <div className='innerBox' style={{margin:'auto'}}>
            <div style={{textAlign:'center'}}>
                <p>{props.text}</p>
                {props.loading!=null && props.loading && <div>
        <img src={LoadingImg} style={{width:300, marginLeft:'auto', marginRight:'auto'}} />
        </div>} 
               {props.errmsg!=null && props.errmsg!=''  && <p style={{color:'red', fontWeight:'bold'}}>{props.errmsg}</p>}
          {((props.loading!=null && !props.loading)|| props.loading==null) && <button className='btn_primary' onClick={props.onProceed}> {props.isAlert?'Ok':'Proceed'}</button>} 
           {!props.isAlert && <button className='btn_sec_red' onClick={props.onCancel}>Cancel</button>} 
            </div>
            
        </div>
       {/*  <div><p>
                {props.msgerr}
            </p></div> */}
          </div>
              
         </div>
  )
}

export default GDialog