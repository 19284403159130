export const questions = [
    {
        id: 1,
        question: 'How much to play?',
        answer: "N200. However Admin can change this amount. You will be notify of the amount when showing Interest"
    },
    {
        id: 2,
        question: 'How do I joing the upcoming game?',
        answer: 'When you login to your account, There will be a button to the right of the screen, You will see the Show Interest button, Click and you will see further instruction'
    },
    {
        id: 3,
        question: 'So I lost, does it means I can not play anymore?',
        answer: 'Yes. You will not be able to continue with the current game. Wait until its finished. New Game created, then show Interest again'
    },
    {
        id: 4,
        question: 'In this current game, I have won 3 levels and lost the forth level, I did not see any winning, Why?',
        answer: 'The only way you see winning are the following, You Cashed Out or Win the Final. If you did not claim your winning by cashing out and you continue to play, then when you loose, you loose it.'
    },
    {
        id: 5,
        question: 'What if my opponent did not show up?',
        answer: 'The system will allow you pick atlease 1 (one) card so you have a higher figure and its your opponent turn. Please make sure you wait till it says game over before you leave the page, else your opponent may later show up and you will loose.'
    },
    {
        id: 6,
        question: 'Me and my oponent lost?',
        answer: 'This case happen, when both opponent did not show up for game.'
    },
    {
        id: 7,
        question: 'What if I get low network?',
        answer: 'We will not be responsible for your network, make sure you have strong internet connect.'
    },
    {
        id: 8,
        question: 'How do I fund my wallet?',
        answer: 'Login to your account, you will see fund wallet, then enter the amount and click the button, it has paystack integrated to make your payment more secure.'
    },
    {
        id: 9,
        question: 'Can I transfer from my bank account to my Two Leaves Games account',
        answer: 'Yes. Login to your account, on the dashboard, see Fund Wallet, enter the amount you want to transfer, click the button, on the Paystack interface, select transfer, you will be provided account number to receive your transfer.'
    },
   
]