import React, { useState , useEffect, useContext} from 'react';
import Logo from '../images/logo.png';

import img1 from '../images/img1.png';
import img2 from '../images/img2.png';
import img6 from '../images/img6.png';
import img7 from '../images/img7.png';
import img8 from '../images/img8.png';
import img9 from '../images/img9.png';

import '../css/admin.css'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faInfo, faDashboard, faBookBookmark, faCalendar,
     faPuzzlePiece , faShieldAlt, faDiceOne, faBahtSign, faChartBar, faTools
    , faBell, faChevronDown, faArrowUp,
    faUser,
    faGift} from '@fortawesome/free-solid-svg-icons';

import { Link, useNavigate } from 'react-router-dom'
import { ShareContext } from '../context/sharecontext';
import * as Constants from '../constants';
import LoadingImg from '../images/loading.gif';
import GCustomerHeader from '../partials/gCustomerHeader';
import GDialog from '../components/gDialog';
import GAdminHeader from '../partials/gAdminHead';
import GAdminSidebar from '../partials/adminSidebar';
import GInput from '../components/gInput';
import Loader from '../components/loader';
import GExpired from '../components/gExpired';
import Pagenated from '../components/pagenated';

function Leaders() {
    const navigate = useNavigate();
    const { profile, adminToken, setAdminProfile } = useContext(ShareContext);
    const [staySigned, setStaySigned] = useState(false)
    const [customers, setCustomers]=useState([]);
    const [fcustomers, setFCustomers]=useState([]);
    const [count,setCount]  =useState(0);
    //load profile info.
  
    const [loading, setLoading] =useState(false);
    const [searchAg,setSearchAg] =useState('');
    const [isExpired, setIsExpired] =useState(false);
    const [page, setPage]=useState({start:0,pick:20, pageno:1});
    const [total, setTotal] =useState(0);

    console.log(profile);
    useEffect(() => {
     
       Load(page);
       //LoadReports();
         }, []);
    
         const Load=async(p)=>{
      
          var urls=`${Constants.rooturl}/admin/customers/leader/${p.start}/${p.pick}`;
          try {
              setLoading(true);
              const resp = await fetch(urls, { 
                method: 'GET',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': adminToken,
                  
                },
              });
              
              const data = await resp.json();
            
              setLoading(false);
              console.log(data);
              if (data.code === 0) {
                 setCustomers(data.customers);
                 setFCustomers(data.customers);
                 if( data.count!=0){
                 
                  setTotal(data.count);
                }
              }else if(data.code==-10){
                setIsExpired(true);
              }else {    
                console.error(data.status);
              }
            } catch (error) {
              setLoading(false);
              console.error(error); 
            }
       }
    
     const onDetails =(g)=>{
      navigate(`/admin/customers/${g.id}`)
         }
    
         const onSearchAg=(value)=>{
          
              if(value!=''){
              const filteredData_ = customers?.filter(item => {
                const searchMatch = item.firstname.toLowerCase().includes(value.toLowerCase()) || (item.lastname?.toLowerCase().includes(value.toLowerCase())) || item.phone.toLowerCase().includes(value.toLowerCase())
                
                return searchMatch 
            })
            setFCustomers(filteredData_);
            }else{
              setFCustomers(customers);
            }
          
          
          
          setSearchAg(value);
      }
      
      const onServerSearch=async()=>{
 
        var urls=`${Constants.rooturl}/admin/searchleader/${searchAg}`;
        try {
            setLoading(true);
            const resp = await fetch(urls, { 
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': adminToken,
                
              },
            });
            
            const data = await resp.json();
          
            setLoading(false);
            console.log(data);
  
            if (data.code === 0) {
              setCustomers(data.customers);
              setFCustomers(data.customers);
            }else if(data.code==-10){
              setIsExpired(true);
            }else {    
              console.error(data.status);
            }
          } catch (error) {
            setLoading(false);
            console.error(error); 
          }
     
    }
    const onSelect=(pick)=>{
      console.log('this is selection value');
  
      console.log(pick);
      if(page.pageno!=pick.id || pick.id==0 || pick.id==100){
        var _p={...page};
        if(!(pick.id==0 || pick.id==100)){
          _p.start =pick.id ==1?0:(((pick.id-1) * _p.pick)) ; 
        }else{
          if(pick.id==0){
            
            _p.start =_p.start+ (_p.pick)  ;
            if(_p.start>total){
              return;
            }
          }else{ //which is 100 Previous
            _p.start =_p.start- (_p.pick)  ;
            if(_p.start<0){
              return;
            }
          }
        }
        _p.pageno=pick.id;
        setPage(_p);
      Load(_p);
      }else{
        console.log('Loaded already')
      }
      
  }
  return (
 
    <div>
      
    
<div className='topAdmin'>
            <GAdminSidebar active="leaders" />
    <div className='contentside'>
    <GAdminHeader />
    <div className='innerBox'>
     <div  style={{width:'80%'}}>
     <div >
                            {/* <FontAwesomeIcon icon={faSearch} /> */}
                           <GInput type="text" placeholder='Search Leader' value={searchAg} onChange={(e) =>onSearchAg(e.target.value) }/>
 <button className='btn_primary' onClick={onServerSearch} >Search</button>
                        </div>
                        <h2>Total Number: {total}</h2>
      <table>
        <tr>
          <th>First name</th>
          <th>Last Name</th>
          <th>Phone </th>
          <th>Email</th>
          <th>Sponsor Code</th>
          <th>Date</th>
          <th>Action</th>
        </tr>
        
        {fcustomers?.map((cu, idx) =>{return (
                    <tr key={idx}>
                            <td>{cu.firstname}</td>
                            <td>{cu.lastname}</td>
                           <td>{cu.phone}</td>
                           <td>{cu.email}</td>
                           <td>{cu.sponsorCode}</td>
                           <td>{Constants.formatDate(cu.createDate) }</td>
                            <td><button className='btn_sec' onClick={()=>onDetails(cu)}>Details</button></td>
                    </tr>
                  )})}
      </table>
      <Pagenated size={page.pick} total={total} maxPage={10}  onPick={onSelect}/>
     </div>
    </div>
    
  
    </div>

</div>
<Loader open={loading} />
<GExpired open={isExpired} type='customer' />  
    </div>

  )
}

export default Leaders