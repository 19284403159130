import React, { useState , useEffect, useContext} from 'react';
import Logo from '../images/logo.png';

import img1 from '../images/img1.png';
import img2 from '../images/img2.png';
import img6 from '../images/img6.png';
import img7 from '../images/img7.png';
import img8 from '../images/img8.png';
import img9 from '../images/img9.png';

import '../css/admin.css'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faInfo, faDashboard, faBookBookmark, faCalendar,
     faPuzzlePiece , faShieldAlt, faDiceOne, faBahtSign, faChartBar, faTools
    , faBell, faChevronDown, faArrowUp,
    faUser,
    faGift} from '@fortawesome/free-solid-svg-icons';

import { Link, useNavigate,useParams } from 'react-router-dom'
import { ShareContext } from '../context/sharecontext';
import * as Constants from '../constants';
import LoadingImg from '../images/loading.gif';
import GCustomerHeader from '../partials/gCustomerHeader';
import GDialog from '../components/gDialog';
import GAdminHeader from '../partials/gAdminHead';
import GAdminSidebar from '../partials/adminSidebar';
import GInput from '../components/gInput';
import GPrompt from '../components/gPrompt';
import Pagenated from '../components/pagenated';
import GExpired from '../components/gExpired';

function Account() {
    const navigate = useNavigate();
    const {id} = useParams();
    const { profile, token, setProfile } = useContext(ShareContext);
    const [staySigned, setStaySigned] = useState(false)
    const [customer, setCustomer]=useState({});
    const [payments, setPayments] = useState([])
    const [level,setLevel]  =useState(null);
    const [bal, setBal] =useState(0);
    const [total, setTotal] =useState(0);

    const [fundamount,setFundamount]=useState(0);
    const [sponsorCode, setSponsorCode]=useState('')
    const [uplines, setUplines]=useState([]);
    const [isFund, setIsFund] =useState(false);
    const [errmsg, setErrmsg]=useState('');
    const [isExpired, setIsExpired] =useState(false);
    const [page, setPage]=useState({start:0,pick:10, pageno:1});

    //load profile info.
  
    const [loading, setLoading] =useState(false);
 //{ code = 0, customers, payments };


    console.log(profile);
    useEffect(() => {
     
       Load(page);
       //LoadReports();
         }, [id]);
    
    const Load=async(p)=>{
      
        var urls=`${Constants.rooturl}/game/customer/account/${p.start}/${p.pick}`;
        try {
            setLoading(true);
            const resp = await fetch(urls, { 
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': token,
                
              },
            });
            
            const data = await resp.json();
          
            setLoading(false);
            console.log(data);
            if (data.code === 0) {
               
               setPayments(data.payment);
               if(data.bal!=0 || data.total!=0){
                 setBal(data.bal);
                 setTotal(data.total);
               }
              
            
            }else if(data.code==-10){
              setIsExpired(true);
            }else {    
              console.error(data.status);
            }
          } catch (error) {
            setLoading(false);
            console.error(error); 
          }
     }
    
const onSelect=(pick)=>{
    console.log('this is selection value');

    console.log(pick);
    if(page.pageno!=pick.id || pick.id==0 || pick.id==100){
      var _p={...page};
      if(!(pick.id==0 || pick.id==100)){
        _p.start =pick.id ==1?0:(((pick.id-1) * _p.pick)) ; 
      }else{
        if(pick.id==0){
          
          _p.start =_p.start+ (_p.pick)  ;
          if(_p.start>total){
            return;
          }
        }else{ //which is 100 Previous
          _p.start =_p.start- (_p.pick)  ;
          if(_p.start<0){
            return;
          }
        }
      }
      _p.pageno=pick.id;
      setPage(_p);
    Load(_p);
    }else{
      console.log('Loaded already')
    }
    
}

  return (
 
    <div>
      {loading && <div>
        <img src={LoadingImg} style={{width:300, marginLeft:'auto', marginRight:'auto'}} />
        </div>}  
    
<div >
<GCustomerHeader active="account" />      
    <div className='cusBody'>
<h2>Account Balance: {bal}</h2>  
   
    <div className='innerBox'>
     <div style={{flex:1}}>
      <h3>Payments</h3>
      <table>
        <tr>
          <th>Pay Ref</th>
          <th>Amount</th>
          <th>Game </th>
          <th>Note</th>
          <th>Source</th>
          <th>Date</th>
          
        </tr>
        
        {payments?.map((cu, idx) =>{return (
                    <tr key={idx}>
                            <td>{cu.refId}</td>
                            <td>{cu.amount}</td>
                           <td>{cu.gameId}</td>
                           <td>{cu.note}</td>
                           
                            <td>{cu.source}</td>
                            <td>{Constants.formatDate(cu.createDate) }</td>
                    </tr>
                  )})}
      </table>
      <Pagenated size={page.pick} total={total} maxPage={3}  onPick={onSelect}/>
     </div>
    
    </div>
 
    </div>

</div>
<GExpired open={isExpired} type='customer' />   
    </div>

  )
}

export default Account