import '../css/ginput.css';
import React, { useState, useEffect } from 'react';


import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faInfo, faCopyright, faPhone } from '@fortawesome/free-solid-svg-icons';


function Pagenated(props) {
    const [staySigned, setStaySigned] = useState(false)
    const {total}=props;
    const [pages, setPages]=useState([]);
    const [pos, setPos] = useState(1);

    
    useEffect(() => { 
       
        var k=0;
       var  _pages=[];
        var page=1;
       // let maxPage=8;
        while(k<total){

            if(page<=props.maxPage){
                _pages.push({id:page,text:page, selected:false});
                page++;
                k=k+props.size; //size of each page
            }else{
                _pages.push({id:0,text:"Next", selected:false},{id:100,text:'Previous', selected:false});
               
                break;
            }
            
        }
        console.log(_pages);
        setPages(_pages);
        
     }, [props]);

     const onPick=(f)=>{
        props.onPick(f);
        setPos(f.id);
     }

  return (
    <div style={{ flex:1}}>
  
    <div  className='pagenate'>
     
     
      <div>Page: </div>
      {pages.map((item, idx) =>{return (
        <div className={pos==item.id?'active':''} key={idx} value={item.id} onClick={()=>onPick(item)}>{item.text}</div>
      )})}
      
    
    </div>
    
</div>
  )
}

export default Pagenated