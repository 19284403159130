import React, { useState , useEffect, useContext} from 'react';
import Logo from '../images/logo.png';

import img1 from '../images/img1.png';
import img2 from '../images/img2.png';
import img6 from '../images/img6.png';
import img7 from '../images/img7.png';
import img8 from '../images/img8.png';
import img9 from '../images/img9.png';

import '../css/dashboard.css'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faInfo, faDashboard, faBookBookmark, faCalendar,
     faPuzzlePiece , faShieldAlt, faDiceOne, faBahtSign, faChartBar, faTools
    , faBell, faChevronDown, faArrowUp,
    faUser,
    faGift,
    faRefresh} from '@fortawesome/free-solid-svg-icons';

import { Link, useNavigate,useParams } from 'react-router-dom'
import { ShareContext } from '../context/sharecontext';
import * as Constants from '../constants';
import LoadingImg from '../images/loading.gif';
import GCustomerHeader from '../partials/gCustomerHeader';
import GDialog from '../components/gDialog';


function ReturnPayment() {
    const { profile } = useContext(ShareContext);
    const [staySigned, setStaySigned] = useState(false)
    const {refid} = useParams();
    const [loading, setLoading]=useState(false);
    const [sucess, setSuccess] =useState(false);
    const [resdata, setResdata]= useState(null);
    const [isRun, setIsRun]= useState(false);


   // const {type} = useParams(); 
   const navigate = useNavigate();
    //const data=bvnData.ResponseData;

    useEffect(() => { 
       // Constants.seo('','');
        
        if(refid!=undefined){
            //verifiy payment 
            onVerifyPayment();

        }else{
            //return to dashbord
        }
    }, []);

    const onVerifyPayment= async()=>{ //doctype=upline always this one
        //for account registration DocumentView
        if(isRun){
          return;
        }
        setIsRun(true);
        const url=`${Constants.rooturl}/game/payment/verify/${refid}`
    
        console.log(`the calling url ${url}`);
        try {
            setLoading(true);
            const resp = await fetch(url, { 
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': '',
              },
            });
            
            const data = await resp.json();
            setLoading(false);
           // onLoad();
           console.log(data);
            if(data.code==0 ){
              // navigate(`/AffidavitDocuments/${data.docId}/edit/${data.uplineId}`)
              setResdata(data);
             
            }else{
               // setAlert({open:true, title:'Payment Status', subtitle:data.status, code:data.code, data, onOk})
            }
          } catch (error) {
            setLoading(false);
            console.error(error);
            setResdata({code:-12, status:error});
            
          }
    }

    const onPrint=()=>{
        window.print();
    }

    const onCancel=()=>{

    }

  return (


   <div>

 <div>
      {loading && <div>
        <img src={LoadingImg} style={{width:300, marginLeft:'auto', marginRight:'auto'}} />
        </div>}  
    
    <GCustomerHeader active="dashboard" />
<div className='cusBody'> 
   
    
    <div className='innerBox'>
    {resdata?.code==0 && <div className='cdiv' style={{textAlign:'center', flex:1}}>   
                          <h1>Payment Successful</h1>
                          <h2>{Constants.formatCurrency(resdata?.payres.data.amount/100)}</h2>
                          <p>{resdata?.payres.data.reference}</p>
                          <div style={{textAlign:'center'}}>
                            <button className='btn_pry_red' onClick={()=>navigate("/dashboard")}>Close</button>
                          </div>
                      </div>} 

                      {resdata?.code!=0 && <div className='cdiv' style={{textAlign:'center'}}>   
                          <h2 style={{color:'red'}}>Payment failed</h2>
                          <h3>Possible duplicate reference or invalid reference</h3>
                      </div>} 
    </div>

    </div>
   
    </div>


   </div>
  )
}

export default ReturnPayment