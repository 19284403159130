import React, { useState , useEffect, useContext} from 'react';
import '../css/admin.css'


import Logo from '../images/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Link, useNavigate } from 'react-router-dom'
import { ShareContext } from '../context/sharecontext';
import { faSignalMessenger } from '@fortawesome/free-brands-svg-icons/faSignalMessenger';
import { faMessage } from '@fortawesome/free-regular-svg-icons';
import { faChessBoard, faDashboard, faGamepad, faMultiply, faUser, faUserAlt } from '@fortawesome/free-solid-svg-icons';
import { faEdge } from '@fortawesome/free-brands-svg-icons/faEdge';



function GAdminSidebar(props) {

    
    useEffect(() => {
      
     }, []);

     const navigate = useNavigate();
     const onLogin=()=>{
         navigate('/login');
     }
     const onRegister=()=>{
        navigate('/register');
    }

    
  const ActLogout=()=>{
  
   //save both token and profile on storage and set current date, so you can compare the date during refresh
  localStorage.removeItem("profile" )
  localStorage.removeItem("token");
  localStorage.removeItem("tokendate"); //this is keeping the date to try to relunch token or not
   navigate('/admin/login');
 }

  return (
    <div className='sidebar'>
    <div className='user'>
    <FontAwesomeIcon icon={faUser}  />
    <p>Admin</p>

    </div>
    <ul className='menu'>
        <li className={props.active=='dashboard'?'underline':''}><Link to={"/admin/dashboard"}>
        <FontAwesomeIcon icon={faDashboard}  />
           <p>Dashboard</p>
        </Link>  
           </li>
          
           <li className={props.active=='customers'?'underline':''}><Link to={"/admin/customers"}>
        <FontAwesomeIcon icon={faUserAlt}  />
           <p>Customers</p>
        </Link>  
           </li >  
           <li className={props.active=='games'?'underline':''}><Link to={"/admin/games"}>
        <FontAwesomeIcon icon={faGamepad}  />
           <p>Games</p>
        </Link>  
           </li> 
           
           <li className={props.active=='leaders'?'underline':''}><Link to={"/admin/leaders"}>
        <FontAwesomeIcon icon={faChessBoard}  />
           <p>Leaders</p>
        </Link>  
           </li> 
           
           <li className={props.active=='users'?'underline':''}><Link to={"/admin/users"}>
        <FontAwesomeIcon icon={faUser}  />
           <p>Admin User</p>
        </Link>  
           </li> 
           <li className={props.active=='account'?'underline':''}><Link to={"/admin/account"}>
        <FontAwesomeIcon icon={faUser}  />
           <p>Payments</p>
        </Link>  
           </li> 
           <li>
            <button className='btn_primary' onClick={ActLogout}>Logout</button>
           </li>
           
    </ul>
</div>
  )
}

export default GAdminSidebar