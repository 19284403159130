import React, { useState } from 'react';
import '../css/home.css';


import Logo from '../images/logo.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash, faInfo, faCopyright, faPhone, faMessage } from '@fortawesome/free-solid-svg-icons';
//import HomeImg from '../images/homebody.png';
import { Link, useNavigate } from 'react-router-dom'
import GHeader from '../partials/gHeader';

function Home() {
    const [staySigned, setStaySigned] = useState(false)
    const navigate = useNavigate();
    const onLogin=()=>{
        navigate('/login');
    }
    const onRegister=()=>{
      navigate('/register');
  }
  return (
    <div className='mainBody'>
      <GHeader active="home" />
      <div className='home_line1'>
        <div>
          <h1>Welcome to Two Leaves Games</h1>
          <p>TWO LEAVES GAMES is a digital based gaming platform where users can compete with themselves in a game of cards with as little as =N=200 and stand a chance of winning millions.</p>
          <button className='btn_primary' onClick={onRegister}>Register Now</button>
        </div>
        <div>
      <img src={Logo}/> 
      </div>
      </div>

      <div className='home_line2'>
          <div className='subjecthead'><span>How to Play</span></div>
          <p>
          Two Leaves Games is a game for two played live with their mobile phones. Meaning that you are in 
charge of your destiny here. After registering with the company online with a minimum of #200 (naira), 
the system will pair your phone number with another person's phone number and you will be notifed at 
the accurate time for the game.
          </p>
          <div className='subjecthead'><span>HOW IS IT PLAYED</span></div>
          <p>It's a simple game for two persons each period,. If the game starts by 12noon you must be with 
            your phone and hook up to respond to your opponent. Ten (10) cards will be on display out of which 
            you and your opponent are expected to select five each randomly. when you are done with your selections 
            the system will automatically reveal the content of each ♦️ card the person with the highest number will be the winner 🏆. This is stage one (st1). Then the system will pear you up with another person's phone number if you win, you proceed to stage two etc. Now stage four is considered a SAFE stage because for every stage upward there's a cash out, so you can cash out at any stage</p>
          <div className='subjecthead'><span>HOW TO EARN</span></div>
          <p>There are three ways to making money while playing the game</p>

        <div className='home_boxes'>
            <div className='box'>
              <h2>LEADERSHIP</h2>
              <p>If you have the status of a leader, a code will be given to you through which people will be empowered to registre into Two Leaves Investment Company. As many as use your code to play the game, cummulatively10% of all the money won will enter your account. Just for introducing people you can make millions</p>
            </div>
            <div className='box'>
              <h2>CASH OUT</h2>
              <p>Cash out starts from level 3 to 15. (If the game is for two hundred naira) Level 1and level 2 (there shall be no cash out). Cash out start from level3. The amount is 400 Naira, Level 4 is 800, it keeps doubling, so level 14 - 1,600,000 level 15 - 2,600,000 all for just #200 game</p>
            </div>
            <div className='box'>
              <h2>LAST MAN STANDING</h2>
              <p>Last man standing for two hundred naira game goes home with #25,000,000 that is if you didn't cash out and finished the game period.</p>
            </div>
          </div> 
          
      </div>
      <div className='footer'>
        Copyright 2024
      </div>
    </div>
  )
}

export default Home