import React, { useState } from 'react';




import LoadingImg from '../images/loading.gif';

function Loader(props) {
    

  return (
    <div className= {`dialog ${props.open?'show':''}`}>
          <div className='content'>
          <div className='innerBox' style={{margin:'auto'}}>
            <div style={{textAlign:'center', margin:'auto'}}>
                <p>{props.text}</p>
                <img src={LoadingImg} style={{width:200}} />
          
            </div>
            
        </div>
       {/*  <div><p>
                {props.msgerr}
            </p></div> */}
          </div>
              
         </div>
  )
}

export default Loader