import React, { useState, useEffect } from 'react';
import '../css/faq.css'
import GHeader from '../partials/gHeader';
import { questions } from '../data/questions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons';


function Faq() {

  const [activeQuestion, setActiveQuestion] = useState(null);

  const toggleAnswer = (questionId) => {
    setActiveQuestion(questionId === activeQuestion ? null : questionId)
  }
 
  
  return (
    <div className='faq'>
        <GHeader active="faq" />
        <div className="questions-title">
            <h1>Frequently Asked Questions</h1>
            <div className="questions">
              {questions.map((question,index ) => {
                return (
                  <div key={index} className='question' onClick={() => toggleAnswer(question.id)}>
                    <div className="top">
                      <h3>{question.question}</h3>
                      <FontAwesomeIcon icon={activeQuestion === question.id ? faMinusCircle :faPlusCircle} className='question-icon' />
                    </div>
                    <div className={activeQuestion === question.id ? "answer show" : "answer"}>
                      {question.answer}
                    </div>
                  </div>
                )
              })}
            </div>
        </div>
    </div>
  )
}

export default Faq